/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 (function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // Home page
        'common': {
            init: function () {



                $.lazyLoadXT.onload.addClass = 'animated fadein';

                if( $("#load-bg-image").length != 0 ){
                   // console.log('load bg image');
                    $("#load-bg-image").attr("src", $('#bg-image').data('bg-image'));

                }

                $('.menu-btn').on('click', function(){

                    if($('.hidden-menu').hasClass('active')){
                        $('.hidden-menu').removeClass('active').animate({x:-740},400);
                    }
                    else{
                        $('.hidden-menu').addClass('active').animate({x:0},400);
                    }



                });

                /**
                 * mobile_menu
                 * */
                $subNavs = $('.nav-home-sub-menu li');

                $(".mobile-menu-link").on('click', function () {
                    $navigation = $(this).siblings('.nav-home-sub-menu');
                    $navigationItems = $navigation.find('li');

                    var tl2 = new TimelineMax();

                    $('.nav-mobile .nav-item').removeClass("active");
                    $(this).parent().addClass('active');

                    tl2
                    // hide submenu
                        .staggerTo($subNavs, 0.2,
                            {
                                left: -40,
                                opacity: 0,
                                height: 0,
                                ease: Power2.easeOut
                            },
                            0.01)
                        // show actibe submenu

                        .staggerTo($navigationItems, 0.4,
                            {
                                left: 0,
                                opacity: 1,
                                height: 60,
                                ease: Power2.easeOut
                            },
                            0.15)


                });
                /* mobile menu link */

                $("[data-mobile-link]").on('click', function () {

                    window.location.href = $(this).data('mobile-link');

                });




            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // HOME
        'home': {
            init: function () {
                // JavaScript to be fired on all pages

                $loadDesktop = ( $( window ).width() <= 575 )  ? false: true;



                $(window).on('resize', function () {
                     // check size
                    $newPageWidth = $( window ).width();
                    if(!$loadDesktop && $newPageWidth > 575){
                       // console.log('refresh page')
                         location.reload();
                    }
                });

                TweenMax.set('.item-desc', {x: 20});




                setTimeout(function () {
                   // $('.nav-aside>.active>a').trigger('click');
                    //activate ca_1
                    startTextAnim();
                    $('.nav-bg-video').animate(
                        {
                            alpha: 1,
                            ease: Back.easIn
                        }, 800);

                }, 500);

                //helper functions
                function truncate(str, length) {
                    // console.log(str, (typeof str) );
                    if (str) {
                        return str.length > length ? str.substring(0, length - 3) + '...' : str
                    }
                    else return " ";
                }




                //instafeed
                var userFeed = new Instafeed({

                    get: 'user',
                    userId: 1298870930,
                    accessToken: '1298870930.1677ed0.6ef3e4aee12a43d0b3588f05312c6338',
                    tagName: 'interoirdesign',
                    /* get: 'tagged',
                     tagName: 'lights',
                     clientId: 'be2ca9f92148491ebb26d0d3af919128',*/
                    limit: 10,
                    mock: true,
                    error: function (response) {
                        //console.log(response)
                    },
                    success: function (data) {
                        //console.log('instafeed loaded',data)
                        var templist = new Array
                        var b = 0
                        for (var i = 0; i < $(data.data).size(); i++) {
                            b++;
                            var caption = (data.data[i].caption) ? data.data[i].caption.text : "";


                            templist.push(
                                '<div class="card insta-card  "> ' +

                                '<div class="card-img-container">' +
                                '<img src="' + data.data[i].images.low_resolution.url + '" alt="" class="card-img-top2 img-fluid"> ' +
                                '<a href="' + data.data[i].link + '" class="btn  btn-outline-primary btn-card btn-card-insta" target="_blank" ><i class="fa fa-instagram"></i> BEKIJK</a>' +
                                '</div>' +

                                '<div class="card-block">' +
                                    /*'<h4 class="card-title">Card title</h4>' +*/
                                '<p class="card-text">' + truncate(caption, 54) + '</p>' +


                                '</div>' +
                                '</div>');


                        }
                        $("#instagramFeed").append(templist.join(""));
                        /* client carousel */

                        $('#instagramFeed').lightSlider({
                            item: 5,
                            loop: true,
                            pager: false,
                            slideMove: 1,
                            auto: true,
                            prevHtml: "<",
                            nextHtml: ">",
                            controls: false,
                            pause: 6000,
                            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                            speed: 600,
                            slideMargin: 20,
                            responsive: [
                                {
                                    breakpoint: 2300,
                                    settings: {
                                        item:7
                                    }
                                },
                                {
                                    breakpoint: 2180,
                                    settings: {
                                        item:6
                                    }
                                },
                                {
                                    breakpoint: 2050,
                                    settings: {
                                        item: 5
                                    }
                                },

                                {
                                    breakpoint: 1830,
                                    settings: {
                                        item: 5

                                    }
                                },
                                {
                                    breakpoint: 1350,
                                    settings: {
                                        item: 4
                                    }
                                },
                                {
                                    breakpoint: 1100,
                                    settings: {
                                        item: 3
                                    }
                                },
                                {
                                    breakpoint: 750,
                                    settings: {
                                        item: 2
                                    }
                                }
                            ]
                        });


                    }
                });



                function getFacebookstream() {

                    $.getJSON("https://graph.facebook.com/587957937939998/posts?fields=full_picture,picture,link,message,created_time&limit=20&access_token=811976955607747|148f039496c8a4b14862b10641e814cd", function (data) {
                        var items = [];
                        var templist = [];
                       // console.log('fb data:', data);
                        $.each(data.data, function (key, val) {
                            items.push("<li id='" + key + "'>" + val.message + "</li>");

                            templist.push(
                                '<div class="card insta-card  fb-card "> ' +
                                '<div class="card-img-container">' +
                                '<img src="' + val.full_picture + '" alt="" class="card-img-top2 img-fluid"> ' +
                                '<a href="' + val.link + '" class="btn  btn-outline-primary btn-card btn-card-insta" target="_blank" ><i class="fa fa-facebook"></i> BEKIJK</a>' +
                                '</div>' +

                                '<div class="card-block">' +
                                    /*'<h4 class="card-title">Card title</h4>' +*/
                                '<p class="card-text">' + truncate(val.message, 54) + '</p>' +


                                '</div>' +
                                '</div>');
                        });


                        $("#facebookFeed").append(templist.join(""));

                        $('#facebookFeed').lightSlider({
                            item: 8,
                            loop: true,
                            pager: false,
                            slideMove: 1,
                            auto: true,
                            prevHtml: "<",
                            nextHtml: ">",
                            controls: false,
                            pause: 6000,
                            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                            speed: 600,
                            slideMargin: 20,
                            responsive: [
                                {
                                    breakpoint: 2300,
                                    settings: {
                                        item:7
                                    }
                                },
                                {
                                    breakpoint: 2180,
                                    settings: {
                                        item:6
                                    }
                                },
                                {
                                    breakpoint: 2050,
                                    settings: {
                                        item: 5
                                    }
                                },

                                {
                                    breakpoint: 1830,
                                    settings: {
                                        item: 5

                                    }
                                },
                                {
                                    breakpoint: 1350,
                                    settings: {
                                        item: 4
                                    }
                                },
                                {
                                    breakpoint: 1100,
                                    settings: {
                                        item: 3
                                    }
                                },
                                {
                                    breakpoint: 750,
                                    settings: {
                                        item: 2
                                    }
                                }
                            ]
                        });
                    });


                }

                function hideMenuDesc() {
                    $('li.active').find('.item-desc').stop().animate(
                        {
                            alpha: 0,
                            ease: Back.easIn
                        }, 200);
                }


                /* animate card images */

                $('body').on('mouseenter', '.card-img-container', function (e) {

                        $(this).find('img').stop().animate({transform: "scale(1.1)"}, 500, 'easeOut');
                    })
                    .on("mouseleave", '.card-img-container', function () {
                        $(this).find('img').stop().animate({transform: "scale(1)"}, 300, 'easeIn');
                    });

                $('body').on('mouseenter', '.btn-card', function (e) {

                        $(this).closest('.card-horizontal').find('.card-img-container').trigger('mouseenter');
                    })
                    .on("mouseleave", '.btn-card', function () {
                        $(this).closest('.card-horizontal').find('.card-img-container').trigger('mouseleave');
                    });



                $('body').on('mouseenter', '.card-img-container', function (e) {

                        $(this).find('.btn-card').animate({opacity : 1 }, 300, 'easeOut')
                    })
                    .on("mouseleave", '.card-img-container', function () {
                        $(this).find('.btn-card').animate({opacity : 0 }, 300, 'easeOut')
                    })
                    .on("click", '.card-img-container', function () {
                      // console.log("CLICK", $(this).find('a').attr('href')  );
                        window.open($(this).find('a').attr('href'));
                    });




                if($loadDesktop){
                    userFeed.run();

                    /* client carousel */

                    $('#carouselCLIENT').lightSlider({
                        item: 7,
                        loop: true,
                        pager: false,
                        slideMove: 1,
                        auto: true,
                        slideMargin: 20,
                        prevHtml: "<",
                        nextHtml: ">",
                        controls: false,
                        pause: 6000,
                        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                        speed: 600,
                        responsive: [
                            {
                                breakpoint: 2250,
                                settings: {
                                    item: 6,
                                    slideMove: 1,
                                    slideMargin: 6,
                                }
                            },
                            {
                                breakpoint: 2000,
                                settings: {
                                    item: 5,
                                    slideMove: 1,
                                    slideMargin: 6,
                                }
                            },
                            {
                                breakpoint: 1500,
                                settings: {
                                    item: 4,
                                    slideMove: 1,
                                    slideMargin: 6,
                                }
                            },
                            {
                                breakpoint: 980,
                                settings: {
                                    item: 3,
                                    slideMove: 1,
                                    slideMargin: 6,
                                }
                            },
                            {
                                breakpoint: 740,
                                settings: {
                                    item: 2,
                                    slideMove: 1,
                                    slideMargin: 6,
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    item: 2,
                                    slideMove: 1
                                }
                            }
                        ]
                    });


                    getFacebookstream();




                    /* MAIN MENU ANIM */


                    $("[data-toggle]").on('click', function () {

                        updateVideo = function () {
                           // console.log('update video: ', video);
                            $("#nav-video").attr('src', video);
                        };

                        var tl2 = new TimelineMax({delay: 0});
                        var animate1 = $(this).data('toggle');
                        var video = $(this).data('video');
                        var logo = $(this).data('logo');

                        //todo animata logo
                        $('.website-logo').attr("src", logo);
                        $('.nav-home-sub-menu li a').removeClass("active");


                        $container = $('#' + animate1);
                        $logo = $container.find('img');
                        $navigation = $(this).siblings('.nav-home-sub-menu');
                        $navigationItems = $navigation.find('li');
                        $ajax2Content = $('#ajax2Content');
                        $subNavs = $('.nav-home-sub-menu li');
                        $allContainers = $('.nav-container');
                        $ajaxContent = $('#ajaxContent');
                        $ajaxWrap = $('#ajaxWrap');
                        $ajax2Wrap = $('#ajax2Wrap');
                        $homeContent = $('#homeWrap');
                        $videoContainer = $("#nav-bg-video");


                        tl2
                        // hide submenu
                            .staggerTo($subNavs, 0.4,
                                {
                                    left: -40,
                                    opacity: 0,
                                    height: 0,
                                    ease: Power2.easeOut
                                },
                                0.05,
                                "-=0.10")
                            // show actibe submenu

                            .staggerTo($navigationItems, 0.4,
                                {
                                    left: 0,
                                    opacity: 1,
                                    height: 60,
                                    ease: Power2.easeOut
                                },
                                0.05,
                                "-=0.10")
                            //animate video / image
                            .to($videoContainer, 1,
                                {
                                    opacity: 0,
                                    scale: 1.5
                                },
                                "-=1"
                            )
                            .addCallback (updateVideo)
                            .to($videoContainer, 1,
                                {
                                    opacity: 1,
                                    scale: 1
                                },
                                "+=0.5")

                            //hide all .nav-container
                            .to($allContainers, 0.4,
                                {
                                    opacity: 0,
                                    x: 200
                                },
                                "0.01")
                            // move .nav-container out of screen
                            .to($allContainers, 0.01,
                                {
                                    x: "100%",
                                    opacity: 0
                                }
                                , '0.41'
                            )
                            // show current container (main menu item)
                            .to($container, 0.041,
                                {
                                    x: "5%",
                                    opacity: 0
                                }, '0.41')

                            .to($container, 0.4,
                                {
                                    x: "0%",
                                    opacity: 1
                                }, '0.42')
                            // Hide ajax 2 container
                            .to($ajax2Wrap, 0.4,
                                {
                                    x: 200,
                                    opacity: 0,
                                    scale: 1
                                }, '0.1')
                            // move ajax container out of screen
                            .to($ajax2Wrap, 0.4,
                                {
                                    x: '100%',
                                    opacity: 0,
                                    scale: 1
                                }, '0.2')
                            // start homepage text anim (if slide = home)
                            .addCallback (startTextAnim)


                        ;

                        $('#home-nav li, .nav-container').removeClass('active');

                        $container.addClass('active');

                        $(this).parent().addClass('active');


                    });






                    /**
                     * LOAD AJAX CONTENT
                     * */

                    var clearAjax = function () {
                        $ajaxContent.html('');
                        $homeContent.css("z-index", 1);
                        $ajaxWrap.css("z-index", 2);
                    };
                    $loader = $('#loader_spinner');


                    function startLoader() {
                        $('body').addClass('loading');
                        TweenMax.to($loader, 1, {
                            rotation: 360,
                            transformOrigin: "50% 50%",
                            ease: Power2.easeInOut,
                            repeat: -1
                        });
                    }

                    function stopLoader() {
                        $('body').removeClass('loading');
                        TweenMax.set($loader, {
                            rotation: 0
                        });
                        TweenMax.killTweensOf($loader);
                    }


                    $('body').on('click', 'a.ajax_1', function (e) {
                        e.preventDefault();

                        startLoader();


                        var tl3 = new TimelineMax({delay: 0.1});


                        var url = $(this).attr("href");
                        var $this = $(this);

                        var loadPage = function () {

                            tl3.pause();
                            $ajax2Content.load(url + " #content", function () {
                                $ajax2Wrap.scrollTop(0);
                                $cards = $('.card-horizontal');
                              //  console.log('cards:', $cards)
                                tl3.staggerFromTo(".card-horizontal", 1, {opacity: 0}, {opacity: 1}, 0.25);
                                tl3.play();
                                stopLoader()
                            });
                        };


                        $('.ajax_1').removeClass('active');
                        $(this).addClass('active');


                        tl3
                            .to($ajax2Wrap, 0.4,
                                {
                                    x: '5%',
                                    opacity: 0,
                                    scale: 1
                                })

                            .addCallback (loadPage)

                            .to($allContainers, 0.5,
                                {
                                    opacity: 0,
                                    x: '5%'
                                },
                                "0.01")
                            .to($allContainers, 0.01,
                                {
                                    x: "100%"
                                }
                            ).addCallback (hideMenuDesc)
                            .to($ajax2Wrap, 0.4,
                                {
                                    x: "0%",
                                    opacity: 1,
                                    scale: 1
                                })


                    });

                    var tl = new TimelineMax({delay: 0.1});

                    $('body').on('click', 'a.ajax_2', function (e) {
                        var url = $(this).attr("href");
                        startLoader();
                        var loadPage = function () {
                            tl.pause();
                            $ajaxContent.load(url + " #content-2", function (response, status, xhr) {
                                tl.play();
                                //animateCanvasImage();
                                if (status == "error") {
                                    var msg = "Sorry but there was an error: ";
                                    console.log(msg + xhr.status + " " + xhr.statusText);
                                }
                                initSingleProject();
                                stopLoader();
                            });
                        };

                        e.preventDefault();

                        tl
                            .to($ajaxWrap, 0.01,
                                {

                                    x: '100%',
                                    ease: Sine.easeOut

                                })

                            .addCallback (loadPage)

                            .to($ajaxWrap, 1.2,
                                {
                                    x: '0%',
                                    ease: Sine.easeOut

                                })

                            .to($homeContent, 1.2,
                                {

                                    x: '-100%',
                                    ease: Sine.easeIn

                                }, '-=1.2')


                    });


                    $('body').on('click', '.close-btn', function (e) {
                        e.preventDefault();
                        $homeContent.css("z-index", 2);
                        $ajaxWrap.css("z-index", 1);

                        tl
                            .to($homeContent, 0.01,
                                {

                                    x: '100%',
                                    ease: Sine.easeOut

                                })
                            .to($ajaxWrap, 1.2,
                                {
                                    x: '-100%',
                                    ease: Sine.easeIn

                                })
                            .to($homeContent, 1.2,
                                {

                                    x: '0%',
                                    ease: Sine.easeOut

                                }, '-=1.2')

                            .addCallback (clearAjax);
                    });


                    /* ANIMATE HOMEPAGE TEXT */
                    var textItems = $('.animated-text span');
                    var numTextItems = textItems.length;
                    var b = 0;
                    var tl4 = new TimelineMax({onComplete: getNextItems}).pause();

                    function startTextAnim() {

                        if ($('.nav-container.active').find('.item_home').length != 0) {
                            tl4.play();
                        }
                        else {
                            tl4.pause();
                        }
                    }

                    function getNextItems() {
                        b++;
                        if (b >= numTextItems) b = 0;

                        var item = $(textItems[b]);
                        $itemTitle = item.find('b');
                        $itemContent = item.find('i');
                        //console.log($itemTitle, $itemContent)
                        tl4
                            .to($itemContent, 0.01,
                                {
                                    alpha: 0,
                                    x: -20,
                                    y: 0
                                })
                            .to($itemContent, 0.5,
                                {
                                    x: 0,
                                    alpha: 1,
                                    ease: Power1.easeIn
                                })
                            .to($itemTitle, 1,
                                {
                                    //scale: 1,
                                    alpha: 1,
                                    ease: Elastic.easeIn.config(1, 0.1)
                                }, "-=0.5")
                            // .addPause(0.8)
                            .to($itemTitle, 0.5,
                                {
                                    //scale: 0.95,
                                    alpha: 0
                                }, "+=3")

                            .to($itemContent, 0.5,
                                {
                                    alpha: 0,
                                    // x:10,
                                    y: 10,
                                    ease: Power1.easeOut
                                }, "-=0.5")


                    }



                }










                /* MAIN MENU DESCRIPTION ANIM */

                $(document).on({
                    mouseenter: function (e) {

                            $(this).find('.item-desc').stop().animate(
                                {
                                    alpha: 1,
                                    x: 0,
                                    ease: Back.easIn
                                }, 200).css('zIndex', -10);

                            if ($(this).find('.item-desc').length != 0) {


                                $('.top-part , .ajax2Content').stop().animate(
                                    {

                                        alpha: 0,
                                        x: 20,
                                        ease: Back.easIn
                                    }, 200);
                            }




                    },

                    mouseleave: function () {

                        $(this).find('.item-desc').stop().animate(
                            {
                                alpha: 0,
                                x: 20,
                                ease: Back.easIn
                            }, 200);
                        if ($(this).find('.item-desc').length != 0) {

                            $('.top-part , .ajax2Content').stop().animate(
                                {

                                    alpha: 1,
                                    x: 0,
                                    ease: Back.easIn
                                }, 500);
                        }
                    },

                    touchstart: function (e) {
                        // remove popup on mobile and tablet
                        $( ".item-desc" ).remove();

                    }
                }, '.nav-aside > li > a');





            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },


    // Home page
        'page': {
        init: function () {


            $("#carousel-showcase").on('swipeLeft', function (e) {

                //console.log('swipe');
                $(this).carousel('prev');

            });

            $("#carousel-showcase").on('swipeRight', function (e) {

               // console.log('swipe');
                $(this).carousel('next');

            });


        },
        finalize: function () {
            // JavaScript to be fired on the home page, after the init JS
        }
    },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },// About us page, note the change from about-us to about_us.
        'single_post': {
            init: function () {
                 //console.log('single_post');
                initSingleProject();

            }
        },
        // About us page, note the change from about-us to about_us.
        'single_showcase': {
            init: function () {
                initSingleProject();


            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

function galleryAnimation(){
    $('.illumi_gallery_item')
        .on("mouseenter", function () {
            $(this).find('img').stop().animate({transform: "scale(1.3)"}, 300, 'easeOut');
        })
        .on("mouseleave", function () {
            $(this).find('img').stop().animate({transform: "scale(1)"}, 200, 'easeIn');
        });
}
    function initSingleProject() {
        animateCanvasImage();
        initGoogleMaps();
        galleryAnimation();
        //playAllVideo();
        //console.log('initSingleProject');
        $('#ajaxContent').find('.main').attr('id', 'ajax-scroll');

        //$.lazyLoadXT.onload.addClass = 'animated fadein';

        setTimeout(function () {
           // console.log('lazy');
            $.lazyLoadXT.scrollContainer = '#content-2';
            $(window).lazyLoadXT();
            SocialShareKit.init();
        }, 100);




        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body, #content-2').animate({
                        scrollTop: (target.offset().top - 190)
                    }, 1000);
                    return false;
                }
            }
        });


        jQuery(document).ready(function ($) {
            $('a[data-rel^=lightcase]').lightcase({
                transition: 'scrollVertical',
                transitionIn: 'elastic',
                showSequenceInfo: false,
                showTitle: false,
                maxWidth: 1900,
                maxHeight: 1060

            });
        });


        // scroll animations

        var controller = new ScrollMagic.Controller();

        // loop through all elements
        $('.animation-trigger').each(function() {

            $element = "#"+$(this).data('target');
            $element2 = "#"+$(this).data('target') +" .content-padding";
           // console.log($element2);

            // build a tween

            var tween = new TimelineMax()
                .from($($element), 0.6, {
                    autoAlpha: 0,
                    y: '+=190', ease:Linear.easeNone
                })
                .from($($element2), 0.4, {
                        autoAlpha: 0,
                        y: '+=50',
                        ease:Linear.easeNone
                    },
                    "+=0.1 ");

            // build a scene
            var scene = new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 'onEnter',
                offset:250

            })

                .setTween(tween) // trigger a TweenMax.to tween
                .addTo(controller);

        });


        var controller2 = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "400%"}});

        function move (what, progress) {
            var to = progress * 90;
            TweenMax.to(what, 0.3, {y: to + "%", overwrite: 5, force3D: true});
        }


        $('.img-background').each(function (trigger, index) {

            var thisEl = $(this);

            /*new ScrollMagic.Scene({triggerElement:  thisEl[0]})
                .setTween(thisEl.find('.img_scroller'), {y: "50%", ease: Linear.easeNone, force3D: true})
                .addTo(controller2);*/


            new ScrollMagic.Scene({triggerElement:  thisEl[0]})

                .on("progress", function (e) {
                    move (thisEl.find('.img_scroller'), e.progress);
                })


                //.setTween(thisEl.find('.img_scroller'), {y: "50%", ease: Linear.easeNone, force3D: true})
                .addTo(controller2);


        });






    }

    function initGoogleMaps() {
        $.each($('.google-map'), function () {
           // console.log('load maps!!!!', $(this));

            var mapDiv = $(this);
            $(function initMap() {

                var map = new google.maps.Map(mapDiv[0], {
                    center: {
                        lat: mapDiv.data('lat'),
                        lng: mapDiv.data('lng')
                    },
                    zoom: mapDiv.data('zoom'),

                    styles: [
                        {
                            "featureType": "all",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "hue": "#ff0000"
                                },
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": -30
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#353535"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#656565"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#505050"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#808080"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#454545"
                                }
                            ]
                        }
                    ]
                });
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(mapDiv.data('lat'), mapDiv.data('lng')),
                    map: map,
                    title: 'marker'
                });

            });


        });
    }

     function playAllVideo(){
        // var promise = document.querySelector('video').play();

         $('video').each(function(){

             var promise =  $(this).play();

             if (promise !== undefined) {
                 promise.then(function() {
                     // Autoplay started!
                 }).catch(function(error) {
                     console.log('error', error)
                     // Autoplay was prevented.
                     // Show a "Play" button so that user can start playback.
                 });
             }
         })
         /**/
     }


    function animateCanvasImage() {
        if($("#image-header").legth <1) return true;

// Properties _____________________________________________


        var pos = 0;
        pos2Min = -3.141592653589793;

        var fps = 40;
        var header = $('header');
        header.data("pos", 1);
        var canvas;


// Animation Logic ________________________________________


        var url = $("#image-header").attr('src');
        var img = new Image();
        img.src = url;
        img.onload = start;



        function start() {

            var canvas = document.querySelector("canvas");
            var ctx = canvas.getContext("2d");
            $('canvas').animate({
                opacity : 1
            });

            function mix(a, b, l) {
                return a + (b - a) * l;
            }

            function upDown(v) {
                return Math.sin(v) * 0.5 + 0.5;
            }

            function render(time) {
                time *= 0.0005;

                resize(canvas);

                var t1 = time;
                var t2 = time * 0.001;

                // for each line in the canvas
                for (var dstY = 0; dstY < canvas.height; ++dstY) {

                    // v is value that goes 0 to 1 down the canvas
                    var v = dstY / canvas.height;

                    // compute some amount to offset the src
                    var off1 = Math.sin((v + 0.5) * mix(3, 12, upDown(t1))) * 10;
                    var off2 = Math.sin((v + 0.5) * mix(3, 12, upDown(t2))) * 10;
                    var off = off1 + off2;

                    // compute what line of the source image we want
                    // NOTE: if off = 0 then it would just be stretching
                    // the image down the canvas.
                    var srcY = dstY * img.height / canvas.height + off;

                    // clamp srcY to be inside the image
                    srcY = Math.max(0, Math.min(img.height - 1, srcY));

                    // draw a single line from the src to the canvas

                    ctx.drawImage(
                        img,
                        0, srcY, img.width, 1,
                        0, dstY, canvas.width, 1);

                }


                requestAnimationFrame(render);
            }

            requestAnimationFrame(render);

            function resize(canvas) {/*
             var width = canvas.clientWidth;
             var height = canvas.clientHeight;*/
                var width = $('.canvas-container').width();
                var height = $('.canvas-container').height();
                if (width != canvas.width || height != canvas.height) {
                    canvas.width = width;
                    canvas.height = height;
                }
            }

        }
    }

})(jQuery); // Fully reference jQuery after this point.
